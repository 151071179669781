@import '../../styles/variables.scss';
.visign__app__input__container {
  margin: 20px 0;
  label,
  input,
  textarea {
    display: block;
    font-size: 18px;
  }
  input,
  textarea {
    margin: 7px 0;
    border: 2px solid $color-grey;
    padding: 7px 12px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    resize: none;
    outline: none;
    &:focus,
    &:active {
      outline: none;
      border-color: $color-green;
    }
  }
  textarea {
    height: 350px;
  }
  .visign__app__mandatory:before {
    content: ' ';
  }
}
