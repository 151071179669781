@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.visign__app__summary__images__container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  img,
  .visign__app__file__preview {
    width: calc(50% - 5px);
    max-width: 200px;
    height: auto;
    margin: 0 5px 5px 0;
    .visign__app__file__preview--icon {
      width: 80px;
    }
  }
}

.visign__app__assemblies__summary__container {
  .visign__app__assemblies__summary__item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 40px 0;
    &:not(:last-child) {
      border-bottom: 2px solid $color-grey;
    }
    .visign__app__file__preview--icon {
      width: 200px;
    }

    .visign__app__assemblies__summary__item__image {
      width: calc(60% - 20px);
      @include breakpoint(md-down) {
        width: 100%;
      }
      > img {
        max-height: 50vw;
        width: auto;
        max-width: 100%;
      }
    }

    .visign__app__assemblies__summary__content {
      width: calc(40% - 20px);
      @include breakpoint(md-down) {
        width: 100%;
      }
    }
  }
}
