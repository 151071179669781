@import '../../styles/variables.scss';
.visign__app__style__select__container {
  .visign__app__style__select__item {
    padding: 30px 0;
  }

  .visign__app__style__select__images {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 800px;
    img {
      margin: 10px 10px 10px 0;
      width: calc(50% - 20px);
    }
  }
}
