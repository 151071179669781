@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

#visign__app__root {
  *,
  *:after,
  *:before {
    box-sizing: content-box !important;
  }
  .visign__app__product__container label {
    box-sizing: border-box !important;
  }
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  h1 {
    margin: 20px 0 !important;
    font-size: 24px;
    font-family: AlbraDisplay-Regular !important;
    font-weight: 500;
  }
  h2 {
    font-size: 18px;
  }
  a {
    color: $color-blue;
    text-decoration: none;
  }
  button.visign__app__button {
    background-color: $color-teal;
    color: #000;
    text-transform: uppercase;
    outline: none;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    font-size: 1.1rem;
    &:disabled {
      background-color: $color-grey;
      color: #fff;
      cursor: default;
    }
  }
  .visign__app__selection__container {
    padding: 30px 0;
    input.visign__app__selection {
      display: none;
      appearance: none;
      &:checked + label:before {
        opacity: 1;
      }
    }
    label.visign__app__selection__label {
      cursor: pointer;
      font-weight: bold;
      position: relative;
      padding: 10px 0 10px 40px;
      display: block;
      &:after {
        left: 0;
        top: 7px;
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        border: 2px solid $color-grey;
        border-radius: 4px;
      }
      &:before {
        opacity: 0;
        content: '';
        width: 18px;
        height: 18px;
        background-image: url(https://s3.eu-north-1.amazonaws.com/assets.dsapp.storefront.visign.fi/static/media/check.dcd8abdc.svg);
        background-repeat: no-repeat;
        left: 6px;
        top: 12px;
        position: absolute;
        transition: all 0.1s;
      }
      &:hover:before {
        opacity: 0.2;
      }
    }
  }
  .visign__app__mandatory {
    color: $color-red;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
  ::placeholder {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
  }
}
