@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.visign__app__header {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 1200px;
  margin: auto;
  padding: 30px;
  .visign__app__header__item {
    font-size: 14px;
    text-align: center;
    width: calc(100% / 6);
    @include breakpoint(md-down) {
      width: 100%;
    }
    .visign__app__header__item--text {
      margin-top: 10px;
    }
    .visign__app__header__item--number {
      margin: auto;
      position: relative;
      border: 1px solid $color-grey;
      background-color: #fff;
      color: #000;
      z-index: 2;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
    }
    &.visign__app__header__item--active {
      font-weight: 600;
      .visign__app__header__item--number {
        background-color: $color-teal;
        color: #000;
        border-color: $color-green;
      }
    }
    &:not(.visign__app__header__item--active) {
      @include breakpoint(md-down) {
        display: none;
      }
    }
  }
  &:after {
    content: '';
    width: 75%;
    top: 44px;
    position: absolute;
    background-color: $color-grey;
    height: 2px;
  }
}

.visign__app__content__section {
  margin: auto;
  padding: 20px 25px;
  max-width: 1200px;
}

.visign__app__main {
  position: relative;
}
