.visign__app__radio__group {
  display: flex;
  flex-wrap: wrap;
  .visign__app__selection__container {
    margin-right: 20px;
  }
  .visign__app__field__error {
    width: 100%;
  }
}
