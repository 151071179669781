@import '../../styles/breakpoints.scss';
.visign__app__language {
  max-width: 1067px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  padding: 30px 7vw 0 30px;
  @include breakpoint(xlg-up) {
    padding-right: 30px;
  }
  .visign__app__language--lang {
    font-weight: 200;
    cursor: pointer;
    &:not(:last-child):after {
      content: '|';
      margin: 0 5px;
      font-weight: 200;
    }
    &.visign__app__language--active {
      font-weight: 500;
    }
  }
}
