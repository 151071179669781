@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.visign__app__assemblies__container {
  .visign__app__assemblies__header {
    border-bottom: 2px solid $color-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    .visign__app__assemblies__header__item {
      background-color: transparent;
      padding: 10px 20px;
      font-size: 14px;
      border: 2px solid $color-grey;
      outline: none;
      margin: 0 2px -5px 2px;
      border-radius: 8px 8px 0 0;
      &.visign__app__assemblies__header__item--active {
        border-bottom-color: #fff;
        padding: 11px 20px;
        margin-bottom: -2px;
      }
    }
  }
  .visign__app__assemblies__preview {
    padding-top: 60px;
    img {
      display: block;
      margin: auto;
      width: auto;
      max-height: 50vh;
    }
  }
  .visign__app__assemblies__categories {
    display: flex;
    flex-wrap: wrap;
    h1 {
      width: 100%;
    }
    .visign__app__assemblies__category {
      label {
        display: block;
        cursor: pointer;
        outline: none;
        border: 2px solid $color-grey;
        background-color: transparent;
        font-size: 12px;
        margin: 5px 5px 0 0;
        padding: 5px 10px;
        text-transform: uppercase;
      }
      input {
        display: none;
        appearance: none;
        &:checked + label {
          background-color: $color-green;
          border-color: $color-green;
          color: #fff;
        }
      }
    }
  }
}

.visign__app__assemblies__category__images {
  padding-top: 20px;
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  .visign__app__assemblies__category__text {
    width: 100%;
  }
  .visign__app__assemblies__category__image {
    margin: 10px 0;
    width: calc(50% - 10px);
    input {
      display: none;
      appearance: none;
      &:checked + label {
        outline: 5px solid $color-teal;
      }
    }
    label {
      cursor: pointer;
      display: block;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &.visign__app__assemblies__category__images--active {
    display: flex;
  }
}

.visign__app__assemblies__header__carousel {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  div {
    font-weight: bold;
    font-size: 20px;
  }
}

.visign__app__assemblies__selected {
  display: flex;
  flex-wrap: wrap;
  h2 {
    width: 100%;
  }
  .visign__app__assemblies__selected--item {
    width: calc(50% - 5px);
    max-width: 200px;
    height: auto;
    margin: 0 5px 5px 0;
    position: relative;
    img {
      max-width: 100%;
    }
    .visign__app__remove__image {
      position: absolute;
      outline: none;
      background: $color-red;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 30px;
      border-radius: 100%;
      top: -6px;
      right: -6px;
      img {
        width: 15px;
        height: auto;
      }
    }
  }
}
