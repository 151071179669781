@import '../../styles/variables.scss';

.visign__app__progress__bar__container {
  padding: 5vh 0 30vh 0;
  margin: 15px;
  text-align: center;
  .visign__app__progress__bar__wrapper {
    position: relative;
    height: 30px;
    margin: auto;
    width: 100%;
    max-width: 600px;
    border: 2px solid $color-grey;
    display: flex;
    .visign__app__progress__bar__indicator {
      transition: width 0.2s;
      background-color: $color-teal;
      animation: slide 3s linear infinite;
      background-size: 200% 200%;
      background-image: linear-gradient(
        90deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(51, 244, 224, 1) 50%,
        rgba(34, 193, 195, 1) 100%
      );

      .visign__app__progress__bar__count {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: 2px;
      }
    }
  }
}

@keyframes slide {
  from {
    background-position: 200%;
  }
  to {
    background-position: 0%;
  }
}
