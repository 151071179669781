@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.visign__app__products {
  margin: auto;
  padding: 20px 25px;
  margin-bottom: 30px;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h1 {
    width: 100%;
  }
}

.visign__app__product__container {
  margin-top: 10px;
  margin-bottom: 10px;
  width: calc(100% / 4 - 10px);
  @include breakpoint(lg-down) {
    width: calc(100% / 2 - 10px);
  }
  @include breakpoint(md-down) {
    width: 100%;
  }
  label {
    height: 100%;
    display: block;
    border: 2px solid $color-grey;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 20px;
    text-align: center;

    i {
      font-size: 14px;
    }
    ul {
      text-align: left;
      li {
        font-size: 14px;
        line-height: 1.5;
      }
    }
    hr {
      width: 70px;
      height: 2px;
      border: none;
      outline: none;
      background-color: #000;
    }
    h1 {
      margin-bottom: 10px;
    }
  }
  input {
    position: absolute;
    appearance: none;
    display: none;
    &:checked + label {
      background-color: $color-light-teal;
    }
  }
}
