@import '../../styles/variables.scss';

.visign__app__upload__container {
  position: relative;
  min-height: 300px;
  max-width: 600px;
  padding: 20px;
  border: 2px dashed $color-grey;
  margin: 30px 0;
  outline: none;
  transition: all 0.2s;
  border-radius: 10px;
  &:focus,
  &:active,
  &:hover,
  &--active {
    border-color: $color-green;
  }
  .visign__app__upload__text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    user-select: none;
    p {
      padding: 0 10px;
    }
  }
  &--error {
    border-color: $color-red;
  }
}

.visign__app__upload__thumbnails {
  display: flex;
  flex-wrap: wrap;
  .visign__app__upload__thumb {
    position: relative;
    padding: 5px;
    border: 1px solid $color-grey;
    margin: 7px;
    height: 80px;
    img {
      display: block;
      width: auto;
      height: 80px;
    }
    .visign__app__remove__image {
      position: absolute;
      outline: none;
      background: $color-red;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 30px;
      border-radius: 100%;
      top: -6px;
      right: -6px;
      img {
        width: 15px;
        height: auto;
      }
    }
  }
}
